import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router";
import {Game, GameEventType, useGame} from "../data/game";
import {Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import {DateTime} from 'luxon'
import {useFormik} from "formik";
import * as yup from "yup";
import {useSnackbar} from "notistack";

export default () => {
    const navigate = useNavigate()
    const {gameId} = useParams()
    const {game} = useGame(gameId || null)
    const [started, setStarted] = React.useState(false)

    if (!game) {
        return <Typography variant="caption">Not found</Typography>
    }

    const handleStart = () => {
        setStarted(true)
        game.startGame()
    }

    const handleEnd = async () => {
        navigate(`/games`)
    }

    return <Stack alignItems="center">
        <Typography variant="h6">Tracking for {game.name}</Typography>
        {
            !started
                ? <Button variant='contained' onClick={handleStart}>Start Game</Button>
                : null
        }
        {
            started
                ? <GameTracker game={game} onEnd={handleEnd}/>
                : null
        }
    </Stack>
}

function GameTracker(props: { game: Game, onEnd: () => void }) {
    const {enqueueSnackbar} = useSnackbar()
    const [trackedGame] = useState(props.game)
    const gameStart = trackedGame?.startEvent ? DateTime.fromMillis(trackedGame.startEvent.timestamp) : null
    const [time, setTime] = useState('00:00')

    const [eventDialogOpened, setEventDialogOpened] = useState(false)

    useEffect(() => {
        if (gameStart) {
            const interval = setInterval(() => {
                const now = DateTime.local()
                const diff = now.diff(gameStart)
                setTime(diff.toFormat('mm:ss'))
            }, 1000)
            return () => clearInterval(interval)
        }
    })

    const handleEnd = async () => {
        trackedGame?.endGame()
        await trackedGame?.save()
        props.onEnd?.()
    }

    const handleEventDialogSubmit = (gameEvent: string) => {
        trackedGame?.addEvent(gameEvent)
        enqueueSnackbar('Event tracked', {variant: 'success'})
        setEventDialogOpened(false)
    }

    const addEvent = (type: GameEventType) => {
        trackedGame?.addEvent(type, type)
        enqueueSnackbar('Event tracked', {variant: 'success'})
    }

    return <Stack spacing={3} alignItems="center">
        <Typography variant="h3">{time}</Typography>
        <Stack direction="column" spacing={2}>
            <Stack>
                <Button variant="contained" color="error" onClick={handleEnd}>End</Button>
            </Stack>
            <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                <Button variant="contained" size="large" onClick={() => addEvent('PASS')}>Pass</Button>
                <Button variant="contained" size="large" onClick={() => addEvent('SHOOT')}>Shoot</Button>
                <Button variant="contained" size="large" onClick={() => addEvent('DRIBBLING')}>Dribbling</Button>
                <Button variant="contained" size="large" onClick={() => addEvent('GOAL')}>Goal</Button>
                <Button variant="contained" size="large" onClick={() => addEvent('PENALTY')}>Penalty</Button>
                <Button variant="contained" size="large" onClick={() => addEvent('TACKLE')}>Tackle</Button>
                <Button variant="contained" size="large" onClick={() => addEvent('FREE_KICK')}>Free kick</Button>

                <Button variant="contained" size="large" onClick={() => setEventDialogOpened(true)}>Other</Button>

                <NewEventDialog open={eventDialogOpened} onClose={() => setEventDialogOpened(false)}
                                onSubmit={handleEventDialogSubmit}/>
            </Stack>
        </Stack>
    </Stack>
}

function NewEventDialog(props: { open: boolean, onClose: () => void, onSubmit: (event: string) => void }) {
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: yup.object({
            name: yup.string().required('Required'),
        }),
        onSubmit: async values => {
            props.onSubmit(values.name)
            formik.resetForm()
        }
    })

    const handleCancel = () => {
        formik.resetForm()
        props.onClose()
    }

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>New Game Event</DialogTitle>
        <DialogContent>
            <Stack style={{marginTop: 2}}>
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="column" spacing={2} style={{paddingTop: 2}}>
                        <TextField id='name'
                                   label='Name'
                                   placeholder='Describe the event'
                                   value={formik.values.name}
                                   onChange={formik.handleChange}
                                   error={formik.touched.name && Boolean(formik.errors.name)}
                                   helperText={formik.touched.name && formik.errors.name}
                        />
                        <Button type="submit" variant="contained">Continue</Button>
                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                    </Stack>
                </form>
            </Stack>
        </DialogContent>
    </Dialog>
}