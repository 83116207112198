import useSWR, {mutate} from "swr";
import {v4 as uuid} from 'uuid'
import { AsyncParser } from 'json2csv'
import {DateTime} from "luxon";
import {snakeCase} from 'lodash'

const GAMES_STORAGE_KEY = 'games';

export function useGames() {
    const {data, error} = useSWR('/games', async () => {
        if (!localStorage.getItem(GAMES_STORAGE_KEY)) {
            return []
        }

        const games = JSON.parse(localStorage.getItem(GAMES_STORAGE_KEY) || '[]')
        return games.map((game: any) => new Game(game.id, game.name, game.events))
    })

    return {games: data, error}
}

export function useGame(gameId: string | null) {
    const {data, error} = useSWR(`/games/${gameId}`, async () => {
        if (!gameId) {
            return null
        }

        if (!localStorage.getItem(GAMES_STORAGE_KEY)) {
            return null
        }

        const games = JSON.parse(localStorage.getItem(GAMES_STORAGE_KEY) || '[]')
        const game = games.find((game: any) => game.id === gameId)
        return game ? new Game(game.id, game.name, game.events) : null
    })

    return {game: data, error}
}

export class Game {
    id: string;
    name: string;
    events: GameEvent[]

    constructor(id: string, name: string, events: GameEvent[] = []) {
        this.id = id;
        this.name = name;
        this.events = events
    }

    get startEvent() {
        return this.events.find((event) => event.type === 'START')
    }

    static async create(id: string, name: string) {
        const game = new Game(id, name)
        const games = JSON.parse(localStorage.getItem(GAMES_STORAGE_KEY) || '[]')

        if (!games) {
            localStorage.setItem(GAMES_STORAGE_KEY, JSON.stringify([game]))
        } else {
            games.push(game)
            localStorage.setItem(GAMES_STORAGE_KEY, JSON.stringify(games))
        }

        await mutate('/games')

        return game
    }

    startGame():GameEvent {
        const gameEvent = new GameEvent(uuid(), 'START', 'START');
        this.events.push(gameEvent)
        return gameEvent
    }

    endGame(): GameEvent {
        const gameEvent = new GameEvent(uuid(), 'END', 'STOP');
        this.events.push(gameEvent)
        return gameEvent
    }

    addEvent(name: string, type: GameEventType = 'EVENT'): GameEvent {
        const gameEvent = new GameEvent(uuid(), type, name);
        this.events.push(gameEvent)
        return gameEvent
    }

    async save() {
        const games = JSON.parse(localStorage.getItem(GAMES_STORAGE_KEY) || '[]')
        const gameIndex = games.findIndex((game: any) => game.id === this.id)

        if (gameIndex === -1) {
            games.push(this)
        } else {
            games[gameIndex] = this
        }

        localStorage.setItem(GAMES_STORAGE_KEY, JSON.stringify(games))

        return mutate('/games')
    }

    async export() {
        const parser = new AsyncParser({
            fields: ['time', 'type', 'name'],
        })

        let csv = ''
        parser.processor
            .on('data', (chunk) => {csv += chunk.toString()})
            .on('end', () => {
                const blob = new Blob([csv], {type: 'text/csv'})
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.download = `${snakeCase(this.name)}.csv`
                link.click()
            })
            .on('error', err => console.error(err))

        const startTime = this.startEvent ? DateTime.fromMillis(this.startEvent.timestamp) : null
        if (!startTime) {
            console.warn('No start event found')
            return
        }

        for (const event of this.events) {
            const eventTime = DateTime.fromMillis(event.timestamp)
            const diff = eventTime.diff(startTime)

            parser.input.push(JSON.stringify({
                time: diff.toFormat('mm:ss'),
                type: event.type,
                name: event.name,
            }))
        }

        parser.input.push(null)

        return parser.promise(true)
    }
}

export type GameEventType = 'START' | 'END' | 'EVENT' | 'PASS' | 'SHOOT' | 'DRIBBLING' | 'GOAL' | 'PENALTY' | 'TACKLE' | 'FREE_KICK'

export class GameEvent {
    id: string
    timestamp: number = Date.now()
    type: GameEventType
    name: string

    constructor(id: string, type: GameEventType, name: string) {
        this.id = id;
        this.type = type;
        this.name = name;
    }

    static createEvent(name: string) {
        return new GameEvent(uuid(), 'EVENT', name)
    }
}