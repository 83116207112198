import * as React from 'react';
import {createRoot} from 'react-dom/client';
import {ThemeProvider} from '@mui/material/styles';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import theme from './theme';
import Games from './pages/Games';
import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import TrackGame from "./pages/TrackGame";
import {SnackbarProvider} from "notistack";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

const router = createBrowserRouter([
    {
        path: '/',
        element: <Games/>,
    },
    {
        path: '/games',
        element: <Games/>,
    },
    {
        path: '/games/:gameId/track',
        element: <TrackGame/>
    }
])

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}>
                <Box>
                    <Header/>
                    <RouterProvider router={router}/>
                </Box>
            </SnackbarProvider>
        </ThemeProvider>
    </React.StrictMode>
);

function Header() {
    return <React.Fragment>
        <AppBar>
            <Toolbar>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    Humbly Tracker
                </Typography>
            </Toolbar>
        </AppBar>
        <Toolbar/>
    </React.Fragment>
}
