import React, {useState} from "react";
import {Game, useGames} from "../data/game";
import {
    Box,
    Button, Dialog, DialogContent, DialogTitle,
    Fab, IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from "react-router";
import {useFormik} from "formik";
import {v4 as uuid} from "uuid";
import * as yup from "yup";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default () => {
    const [newGameDialogOpened, setNewGameDialogOpened] = useState(false);
    const navigate = useNavigate();

    const handleSubmitNewGame = (game: Game) => {
        setNewGameDialogOpened(false)
        navigate(`/games/${game.id}/track`)
    }

    return <Stack>
        <Typography variant="subtitle1">Games</Typography>

        <GamesList/>

        <Box style={{alignSelf: 'end'}}>
            <Fab color="primary" onClick={() => setNewGameDialogOpened(true)}>
                <AddIcon/>
            </Fab>
            <NewGameDialog open={newGameDialogOpened} onSubmit={handleSubmitNewGame}
                           onClose={() => setNewGameDialogOpened(false)}/>
        </Box>
    </Stack>
}

function GamesList() {
    const {games} = useGames()

    if (games && games.length > 0) {
        return <List>
            {games?.map((game: Game) => <GamesListItem key={game.id} game={game}/>)}
        </List>
    } else {
        return <Typography variant="caption">No games recorded</Typography>
    }
}

function GamesListItem(props: { game: Game }) {
    const handleExport = async () => {
        return props.game.export()
    }

    return <ListItem secondaryAction={
        <IconButton onClick={handleExport}>
            <FileDownloadIcon/>
        </IconButton>
    }>
        <ListItemButton>
            <ListItemText primary={props.game.name}/>
        </ListItemButton>
    </ListItem>
}

function NewGameDialog(props: { open: boolean, onClose: () => void, onSubmit: (game: Game) => void }) {
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: yup.object({
            name: yup.string().required('Required'),
        }),
        onSubmit: async values => {
            const game = await Game.create(uuid(), values.name)
            props.onSubmit(game)
        }
    })

    const handleCancel = () => {
        formik.resetForm()
        props.onClose()
    }

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>New Game</DialogTitle>
        <DialogContent>
            <Stack style={{marginTop: 2}}>
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="column" spacing={2} style={{paddingTop: 2}}>
                        <TextField id='name'
                                   label='Name'
                                   value={formik.values.name}
                                   onChange={formik.handleChange}
                                   error={formik.touched.name && Boolean(formik.errors.name)}
                                   helperText={formik.touched.name && formik.errors.name}
                        />
                        <Button type="submit" variant="contained">Continue</Button>
                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                    </Stack>
                </form>
            </Stack>
        </DialogContent>
    </Dialog>
}